// px
$px: 0.0625rem;

// Speeds
$cheetah: 75ms;
$rabbit: 150ms;
$turtle: 200ms;

// Font sizes (Inspired by David Kadavy's 3:4 Typographic Scale)
$font-size-tiny: 5 * $px;
$font-size-x-small: 7 * $px;
$font-size-small: 9 * $px;
$font-size-medium: 12 * $px;
$font-size-regular: 16 * $px;
$font-size-large: 18 * $px;
$font-size-x-large: 21 * $px;
$font-size-big: 28 * $px;
$font-size-x-big: 37 * $px;
$font-size-giant: 40 * $px;
$font-size-x-giant: 67 * $px;
$font-size-huge: 89 * $px;
$font-size-x-huge: 119 * $px;
$font-size-biggest: 159 * $px;

// Font Weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

// Font Families
$font-family-text: 'Lato', sans-serif;
$font-family-display: source-serif-pro, Georgia, 'Times New Roman', Times, serif;
$font-family-code: source-code-pro, Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace;
