@mixin btn($name, $color, $text-color) {
  @include e(btn-#{$name}) {
    background-color: $color;
    border: 0;
    color: $text-color;

    &:hover {
      background-color: darken($color, 10%);
      color: $text-color; }

    &:active {
      color: $text-color; }

    @include m(outline) {
      background-color: transparent;
      border: 2px solid $color;
      color: $color;

      &:hover {
        @if $name != light {
          background-color: $color;
          color: $text-color; }
        @else {
          color: $white; } } }

    @include m(icon) {
      background-color: transparent;
      border: 0;
      color: $color;

      &:hover {
        color: darken($color, 10%); } }
    @content; } }

@mixin btn-icon($name, $color) {
  @include e(#{$name}) {
    border-color: $color;

    [class^="icon-"] {
      color: $color; }

    &:hover {
      background-color: $color;

      [class^="icon-"] {
        color: white; } }

    @include m(light) {
      border-color: white;

      [class^="icon-"] {
        color: white; }

      &:hover {
        background-color: white;

        [class^="icon-"] {
          color: $color; } } } } }
