.Path {
  background-image: url(../img/path.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 1320px;
  margin: ($big-white-space-size * 2) 0;

  @include e(item) {
    position: relative;

    h2,
    p {
      color: white;
      padding: 0 $medium-white-space-size; }

    img {
      margin: auto; }

    @include m(1) {
      top: -187px;

      .title {
        margin-top: 25px; } }

    @include m(2) {
      top: -210px;

      .title {
        margin-top: 100px; } }

    @include m(3) {
      top: -52px;

      .title {
        margin-top: 56px; } }

    @include m(4) {
      top: -46px;

      .title {
        margin-top: 53px; } }

    @include m(5) {
      top: 122px;

      .title {
        margin-top: 45px; } }

    @include m(6) {
      top: 127px;

      .title {
        margin-top: 29px; } } } }
