.Products {
  background-image: linear-gradient(-135deg, $gray 0%, $lightgray 100%);

  @include e(slide) {
    height: 350px;

    .u-row,
    .u-column {
      height: 100%; } }

  .Slide {
    @include e(image) {

      img {
        margin: auto; } }

    @include e(description) {
      padding: $medium-white-space-size $white-space-size;

      p {
        font-weight: $font-weight-light;
        line-height: 1.8;
        padding: $small-white-space-size 0;
        text-align: left; } }

    @include e(title) {
      font-size: $font-size-x-large;
      font-weight: $font-weight-bold !important;
      padding: $small-white-space-size 0; }

    @include e(button) {
      padding: $medium-white-space-size;
      text-align: csenter; } }

  @include e(item) {
    padding: $small-white-space-size;

    a {
      margin: $medium-white-space-size 0; } } }
