// Colors
$default-color: #424242;
$primary-color: #CE002C;
$lightgray: #EEEEEE;
$gray: #C0C0C0;
$dark: #202020;

$info-color: #4AA3DE;
$warning-color: #F2CE64;
$danger-color: #F04040;

$twitter-color: #5EA9DD;
$facebook-color: #3B5998;
$instagram-color: #37588B;
$pinterest-color: #CB2027;
$google-color: #DF4A32;
