// Buttons
.u-btn {
  @include prefixer(border-radius, $small-white-space-size, webkit moz spec);
  @include prefixer(transition, .2s, webkit moz spec);
  border: 2px solid transparent;
  display: inline-block;
  font-size: $font-size-regular - (2 * $px);
  font-weight: 700;
  height: 30px;
  line-height: 30px;
  padding: 0px $medium-white-space-size;
  text-align: center;

  &[disabled] {
    background-color: $gray;

    &:hover {
      background-color: $lightgray; } }

  @include e(group) {

    .u-btn {
      display: inline-block;
      margin: ($small-white-space-size / 2); }

    @include m(block) {
      .u-btn-item {
        display: block; } } }

  @include e(small) {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    height: 20px;
    letter-spacing: 0.5px;
    line-height: 20px;
    padding: 0 $small-white-space-size;
    text-transform: uppercase; }

  @include e(middle) {
    font-size: 14 * $px;
    height: 40px;
    line-height: 40px;

    [class^="icon-"] {
      margin-right: $small-white-space-size;
      top: 4px; } }

  @include e(large) {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    height: 50px;
    line-height: 50px;
    padding: 0 $white-space-size;

    @include m(lower) {
      font-size: $font-size-regular;
      text-transform: initial !important; } }

  @include e(x-large) {
    font-size: $font-size-large;
    height: 60px;
    line-height: 60px;
    padding: 0 $white-space-size; }

  @include e(icon) {
    display: block;
    height: 40px;
    padding: 0;
    text-align: center;
    width: 40px;

    [class^="icon-"] {
      top: 0 !important;
      line-height: 40px; }

    @include m(small) {
      height: 25px;
      width: 25px;

      [class^="icon-"] {
        font-size: $font-size-medium !important;
        line-height: 25px;
        top: -2px !important; } } }

  [class^="icon-"] {
    font-size: $font-size-x-large;
    left: .5px;
    position: relative; }

  @include btn-icon(twitter, $twitter-color);
  @include btn-icon(facebook, $facebook-color);
  @include btn-icon(instagram, $instagram-color);
  @include btn-icon(pinterest, $pinterest-color);

  @include e(content) {
    height: auto;
    line-height: $font-size-large;
    padding-bottom: $small-white-space-size;
    padding-left: $white-space-size;
    padding-right: $white-space-size;
    padding-top: $small-white-space-size;

    p {
      font-size: $font-size-medium; }

    p:first-child {
      font-size: $font-size-regular; } } }

// Forms
.u-form {
  width: 100%;

  input,
  optgroup,
  select,
  textarea {
    @include prefixer(transition, .2s, webkit moz spec);
    height: 50px;
    padding: 0 20px; }

  textarea {
    padding: 20px; }

  select {
    width: 100%; }

  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    font-size: $font-size-large;
    height: 50px;
    padding: 0 20px; }

  input[type="text"],
  input[type="password"] {
    width: 100%; }

  @include e(control) {
    @include clearfix;
    box-sizing: border-box;
    padding: 10px;
    width: 100%; }

  @include e(error) {
    border-radius: $small-white-space-size;
    padding: $small-white-space-size;

    p {
      font-size: $font-size-medium; } }

  @include e(groupLine) {
    display: flex;
    margin: -$small-white-space-size;

    span {
      display: block;
      font-size: $font-size-medium;
      text-align: center;
      margin-bottom: $x-small-white-space-size;
      width: 100%; } } }

.u-formGroupV {
  .u-form {
    @include e(control) {
      border-bottom: 0px;
      padding: 0;

      @include m(first) {
        @include prefixer(border-radius, 10px 10px 0px 0px, webkit moz spec); }

      @include m(last) {
        margin-top: -2px;
        @include prefixer(border-radius, 0px 0px 10px 10px, webkit moz spec); } } } }

.u-formGroupH {
  .u-form {
    @include e(control) {
      border-left: 0px;
      padding: 0;

      @include m(first) {
        @include prefixer(border-radius, 10px 10px 0px 0px, webkit moz spec); }

      @include m(last) {
        margin-left: -2px;
        @include prefixer(border-radius, 0px 0px 10px 10px, webkit moz spec); } } } }
