.theme {
  @include e(page) {
    @include clearfix;
    display: table;
    position: relative;
    width: 100%;

    @include e(container) {

      @include m(normal) {
        height: 100%;
        margin-top: -120px; } }

    @include e(content) {
      padding: $white-space-size 0;
      text-align: center;
      width: 100%;

      @include e(title) {
        margin-bottom: auto;
        padding-bottom: $white-space-size;
        text-align: center;

        @include m(normal) {
          align-items: center;
          display: flex;
          height: 120px;
          justify-content: center;
          padding: 0; }

        @include m(dark) {
          p {
            color: white !important; } }

        p {
          border-radius: 50px;
          border: 1px solid $primary-color;
          color: $primary-color;
          display: inline-block;
          font-size: $font-size-x-large;
          height: 50px;
          line-height: 47px;
          padding: 0 $white-space-size; } }

      @include m(header) {
        padding-top: $big-white-space-size; }

      @include m(section) {
        margin: $medium-white-space-size 0; }

      @include m(small) {
        margin: auto;
        width: $main-layout-size; }

      @include m(x-small) {
        margin: auto;
        width: 800px; } } }

  @include e(panel) {
    background-color: white;
    border-radius: $small-white-space-size; }

  @include btn(primary, $primary-color, white);

  @include e(text) {
    @include e(left) {
      text-align: left; }

    @include e(right) {
      text-align: right; }

    @include e(light) {
      font-weight: $font-weight-light; } }

  @include e(hr) {
    background-color: white;
    border: 0;
    height: 1px;
    margin: $white-space-size 0;
    mix-blend-mode: overlay;
    opacity: .3; }

  @include e(form) {
    text-align: left;

    input,
    optgroup,
    select,
    textarea {
      background-color: white;
      border: 1px solid $default-color;
      width: 100%; }

    textarea {
      min-height: 200px;

      &:disabled {
        background-color: $default-color;
        border-color: $gray; } }

    select {
      @include prefixer(appearance, none, webkit moz spec);
      background: white url(/img/arrow.png) no-repeat right .75rem center;
      background-position-y: 19px;
      background-size: 17px auto; }

    @include e(input) {
      input,
      optgroup,
      select,
      textarea {
        background-color: white;
        border: 1px solid $default-color; }

      select {
        @include prefixer(appearance, none, webkit moz spec);
        background: white url(/img/arrow.png) no-repeat right .75rem center;
        background-position-y: 19px;
        background-size: 17px auto;
        padding: 0 $small-white-space-size;
        font-size: $font-size-medium; }

      @include m(small) {
        input,
        optgroup,
        select,
        textarea {
          border-radius: $x-small-white-space-size;
          text-align: center;
          line-height: 30px;
          height: 30px;
          width: 50px; } }

      @include m(fluid) {
        input,
        optgroup,
        select,
        textarea {
          width: 100%; } } }

    @include e(select) {
      @include m(small) {
        select {
          background-position-y: 9px;
          border-radius: $x-small-white-space-size;
          height: 30px;
          width: 100%; } } }

    .checkbox:not(:checked),
    .checkbox:checked,
    .radio:not(:checked),
    .radio:checked {
      display: none; }

    .checkbox:not(:checked) + label,
    .checkbox:checked + label,
    .radio:not(:checked) + label,
    .radio:checked + label {
      cursor: pointer;
      display: inline-block;
      padding-left: 40px;
      padding-right: 40px;
      position: relative;
      text-align: left; }

    .checkbox:not(:checked) + label:before,
    .checkbox:checked + label:before {
      background: white;
      border: 2px solid $default-color;
      content: '';
      height: 21px;
      left: 0;
      position: absolute;
      top: calc(50% - 13px);
      width: 21px; }

    .checkbox:not(:checked) + label:after,
    .checkbox:checked + label:after {
      color: $primary-color;
      content: "\e900";
      font-family: 'icomoon';
      font-size: 18px;
      left: 6px;
      line-height: 0.8;
      position: absolute;
      top: calc(50% - 11px);
      transition: all .2s; }

    .checkbox:not(:checked) + label:after,
    .radio:not(:checked) + label:after {
      opacity: 0;
 }      //transform: scale(0)

    .checkbox:checked + label:after,
    .radio:checked + label:after {
      opacity: 1;
 }      //transform: scale(1)

    .checkbox:checked:focus + label:before,
    .checkbox:not(:checked):focus + label:before {
      border: 2px solid $dark; }

    .radio:not(:checked) + label:before,
    .radio:checked + label:before {
      background: white;
      border-radius: 50%;
      border: 2px solid $default-color;
      content: '';
      height: 23px;
      left: 0;
      position: absolute;
      top: -4px;
      width: 23px; }

    .radio:not(:checked) + label:after,
    .radio:checked + label:after {
      background-color: $primary-color;
      border-radius: 50%;
      content: "";
      height: 15px;
      left: 6px;
      position: absolute;
      top: 2px;
      transition: all .2s;
      width: 15px; }

    .toggle {
      display: none;
      &, &:after, &:before,
      & *, & *:after, & *:before,
      & + label {
        box-sizing: border-box;
        &::selection {
          background: none; } }
      + label {
        background: white;
        border-radius: 2em;
        border: 2px solid $dark;
        cursor: pointer;
        display: block;
        margin: auto;
        outline: 0;
        padding: 2px;
        position: relative;
        transition: all .4s ease;
        user-select: none;
        width: 67px;
        &:after, &:before {
          content: "";
          display: block;
          height: 25px;
          position: relative;
          width: 25px; }
        &:after {
          background: white;
          border-radius: 50%;
          border: 2px solid $dark;
          left: 0;
          transition: all .2s ease; }
        &:before {
          display: none; } }
      &:checked {
        + label {
          background-color: $primary-color;

          &:after {
            left: 50%; } } } }

    @include e(control) {
      padding: $small-white-space-size;

      @include e(title) {
        color: $dark;
        font-weight: $font-weight-bold; } }

    @include m(focus) {
      box-shadow: inset 0px 0px 20px 0px rgba(18, 197, 142, 0.30); }

    @include m(error) {
      color: $danger-color;
      box-shadow: inset 0px 0px 20px 0px rgba(229, 47, 60, 0.40); }

    @include m(content) {
      margin-bottom: $medium-white-space-size; }

    .u-form {
      @include e(group) {
        display: table;
        width: 100%;

        [type="text"],
        span {
          display: table-cell; }

        span {
          background-color: white;
          border-radius: $small-white-space-size 0 0 $small-white-space-size;
          border: 2px solid $default-color;
          border-right: 0;
          height: 46px;
          line-height: 46px;
          padding: 0 $medium-white-space-size;
          width: 21px;

          [class^="icon-"] {
            font-size: $font-size-x-large;
            left: .5px;
            line-height: 40px;
            position: relative;
            top: 2px; } }

        [type="text"] {
          border-radius: 0 $small-white-space-size $small-white-space-size 0; } } }

    @include e(group) {
      margin: 0; } } }

@include media($lg) {
  .theme {
    @include e(page) {
      @include e(content) {
        @include m(small) {
          width: 100%; }
        @include m(x-small) {
          width: 100%; } } } } }
