.Slides {
  background-image: linear-gradient(-135deg, $gray 0%, $lightgray 100%);

  @include e(slide) {
    padding: $medium-white-space-size; }

  .Slide {
    @include e(image) {
      padding: $medium-white-space-size;

      img {
        margin: auto; } }

    @include e(description) {
      padding: $medium-white-space-size;

      p {
        text-align: left; } }

    @include e(title) {
      font-size: $font-size-x-large;
      padding: $medium-white-space-size 0; }

    @include e(button) {
      padding: $medium-white-space-size;
      text-align: center; } } }
