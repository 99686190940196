.u-row {
  @include row;
  position: relative;

  @include m(center) {
    justify-content: center; }

  @include m(items) {
    align-items: center; }

  @include m(right) {
    justify-content: flex-end; } }

.u-column {
  @include prefixer(box-sizing, border-box, webkit moz spec);

  @include m(flex) {
    display: flex;

    @include e(center) {
      align-items: center; } }

  @include m(center) {
    margin: auto; } }

@each $device, $size in $breakpoints {
  @for $i from 1 through $columns {
    $column-list: null;
    $column-list: append($column-list, unquote(".u-column--#{$device}#{$i}"), comma);
    #{$column-list} {
      @include column($i); } } }

@each $device, $size in $breakpoints-desc {
  @include media($size) {
    @for $i from 1 through $columns {
      $column-list: null;
      $column-list: append($column-list, unquote(".u-column--#{$device}#{$i}"), comma);
      #{$column-list} {
        @include column($i); } } } }
