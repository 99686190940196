* {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

body {
  @include clearfix;
  background-color: white;
  color: $default-color;
  font-family: $font-family-text;
  font-size: $font-size-regular;
  line-height: 1.6; }

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0; }

img {
  display: block; }

// Text
p {
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0; }

h1 {
  font-size: $font-size-x-big; }
h2 {
  font-size: $font-size-big; }
h3 {
  font-size: $font-size-x-large; }
h4 {
  font-size: $font-size-large; }

// Links
a {
  color: $primary-color;
  outline: 0;
  text-decoration: none;

  &:hover {
    color: lighten($primary-color, 10%); } }

// Forms
button,
input,
optgroup,
select,
textarea {
  @include prefixer(box-sizing, border-box, webkit moz spec);
  color: $default-color;
  font: inherit;
  margin: 0;

  &:focus {
    outline: none; } }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  @include prefixer(box-sizing, border-box, webkit moz spec);
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

fieldset {
  border: 0; }

::-webkit-input-placeholder {
  color: $default-color; }

:-moz-placeholder {
  color: $default-color;
  opacity: 1; }

::-moz-placeholder {
  color: $default-color;
  opacity: 1; }

:-ms-input-placeholder {
  color: $default-color; }

:placeholder-shown {
  color: $default-color; }
